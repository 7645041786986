import React, { Component, useState } from "react"
import styled from "@emotion/styled"
import { theme } from "../Styles/theme"
import _ from "lodash"
import { HeadingSecondary } from "../Styles/headers"
import { SectionHero } from "../Styles/text"
import { navigate } from "gatsby"
import { BtnOutline } from "../Styles/buttons"
import { AlertBtn } from "../Styles/buttons"
import GraduationHats from "../images/graduation-hats.jpg"

const ModalInner = styled.div`
  background: white;
  width: 100%;
  height: 100%;
`
    // background: url('${GraduationHats}');

const ModalTop = styled.div`
    background: #f5f5f5;
    background-size: 50% auto;
    position: relative;
    padding: 1.4rem .8rem;
  `
const ModalClose = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
  background: #333;
  width: 27px;
  color: #ddd;
  verticle-align: middle;
  text-align: center;
  border-radius: 50%;
  font-size: 2rem;

  &:hover {
    cursor: pointer;
  }
`
const ModalContent = styled.div`
  text-align: center;
  background: white;
  max-width: 90%;
  margin: 0 auto;
  opacity: 0.95;
  padding: 1.4rem 2rem;

  p {
    margin: 1rem 0;

    &:first-child {
      font-weight: bold;
    }
  }
`
const ModalBottom = styled.div`
  background: #f7fafc;
`
const ModalForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  max-width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  padding: 1rem 0.8rem;
`
const ModalFormGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 31%;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    width: 100%;
  }
  label {
  }
  input {
    font-size: 1rem;
    text-align: start;
    vertical-align: middle;
    width: 100%;
    padding: 1rem;

    &:focus {
      outline: none !important;
      border: 2px solid #8d9aa7;
    }
  }
`
const ModalFormSubmit = styled.button`
  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    width: 100%;
  }
  width: 31%;
  font-size: 1rem;
  padding: 1rem;
  margin: 1rem 0 0;
  border: none;
  background: linear-gradient(to right, #1c84a6, #3CB371);
  color: white;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity: 0.95;
  }
`
export default function ModalFormPop(props) {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [wrongMail, setWrongMail] = useState("")

  console.log(name)
  console.log(email)

  const handleChange = e => {
    switch (e.target.id) {
      case "input-name":
        setName(e.target.value)
        console.log(name)
        break
      case "input-email":
        setEmail(e.target.value)
        break
      default:
        console.log("something went wrong")
    }
  }

  const newSubscriber = e => {
    e.preventDefault()

    if (name && email) {
      const url = `/.netlify/functions/freejs-optin?name=${name}&email=${email}`
      fetch(url)
        .then(res => res.json())
        .then(data => {
          if (data.error) {
            setWrongMail(data.message)
            setName("")
            setEmail("")
            setTimeout(_ => {
              setWrongMail("")
            }, 2000)
          } else {
            setName("")
            setEmail("")
            navigate("/optin-confirm/")
            document.body.style.overflow = "auto"
          }
        })
        .catch(err => console.log(err))
    }
  }

  return (
    <ModalInner>
      <ModalTop>
        <ModalClose onClick={_.debounce(props.closeModal, 300)}>
          &times;
        </ModalClose>
        <ModalContent>
          <p>FREE COURSE</p>
          <HeadingSecondary style={{ lineHeight: "1.2" }}>
            Start Learning Javascript Now
          </HeadingSecondary>
          {/* <p>Enter your name and email to start watching.</p> */}
        </ModalContent>
      </ModalTop>
      <ModalBottom>
        <ModalForm onSubmit={newSubscriber}>
          <ModalFormGroup>
            <label>First Name</label>
            <input
              type="text"
              key="lorem"
              name="name"
              id="input-name"
              value={name}
              onChange={handleChange}
              required
            />
          </ModalFormGroup>
          <ModalFormGroup>
            <label>Email</label>
            <input
              type="email"
              key="ipsum"
              name="name"
              id="input-email"
              value={email}
              onChange={handleChange}
              required
            />
          </ModalFormGroup>
          {wrongMail !== "" ? <button>{wrongMail}</button> : ""}
          <ModalFormSubmit type="submit">GET ACCESS</ModalFormSubmit>
        </ModalForm>
      </ModalBottom>
    </ModalInner>
  )
}

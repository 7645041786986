import React, { Component, useState } from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { Section } from "./../Styles/section"
import { HeadingSecondary, HeadingTertiary } from "../Styles/headers"
import { SectionHero } from "../Styles/text"
import RpsImg from "./../images/rps-project.png"
import UnitConvImg from "./../images/unit-converter-project.png"
import ClocklyImg from "./../images/clock-project.png"
import RpsGif from "./../images/rps.gif"
import UnitConvGif from "./../images/unit-converter.gif"
import ClocklyGif from "./../images/clockly.gif"
import { theme } from "./../Styles/theme"

const ProjectsWrapper = styled.section`
  margin: 4rem 0 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    display: block;
  }
`
const ProjectsItem = styled.div`
  width: 32%;
  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    width: 100%;
  }
`
const ProjectsImg = styled.img`
  width: 100%;
  box-shadow: ${theme.boxShadows.images};
`
export default function FreeJsProjects() {
  return (
    <Section backgroundColor="secondary" style={{ padding: "2rem 2rem" }}>
      <HeadingSecondary css={{ textAlign: "center" }}>
        What You'll Build
      </HeadingSecondary>
      <SectionHero style={{ textAlign: "center"}}>
        Three simple yet fun projects.
      </SectionHero>
      <ProjectsWrapper>
        <ProjectsItem><ProjectsImg src={ClocklyGif} alt="digital clock"></ProjectsImg></ProjectsItem>
        <ProjectsItem><ProjectsImg src={RpsGif} alt="Rock Paper Scissors Game"></ProjectsImg></ProjectsItem>
        <ProjectsItem><ProjectsImg src={UnitConvGif} alt="Unit Converter"></ProjectsImg></ProjectsItem>
      </ProjectsWrapper>

    </Section>
  )
}

import React from "react"
import MainNav from "../Components/navbar"
import MainFooter from "../Components/footer"
import Seo from "../Components/seo"
const LandingLayout = props => {
  return (
    <div>
      <Seo />
      {props.children}
      <MainFooter />
    </div>
  )
}

export default LandingLayout

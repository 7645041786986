import React, { Component, useState } from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import { theme } from "../Styles/theme"
import _ from "lodash"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import { HeadingTertiary } from "../Styles/headers"
import { SectionHero, SectionTagLine } from "../Styles/text"
import { Section } from "../Styles/section"
import BabyImg from "../images/baby.svg"
import useModal from "./useModal"
import Img from "gatsby-image"
import ModalFormPop from "./modalForm"
import FreeJSModalForm from "./freejsModalForm"

const BtnMedium = css`
  background: ${theme.colors.primary};
  padding: ${theme.pm.pm100} ${theme.pm.pm200};
  color: #fff;
  display: inline-block;
  border: none;
  font-size: ${theme.text.text200};
  box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.15);
`

const BtnLarge = css`
  background: #2d80fa;
  padding: ${theme.pm.pm200} ${theme.pm.pm300};
  color: #fff;
  display: inline-block;
  border: none;
  font-size: ${theme.text.text400};
  box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.15);
`

const HeaderSection = styled.div`
  color: ${theme.colors.grey900};
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: auto;
  line-height: 1.4;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    padding-bottom: calc(${props => props.theme.sizes.navbarHeight} + 1.5rem);
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;

    h3 {
      line-height: 1.4;
    }
  }
`

const HeaderImg = css`
  width: 100%;
  max-width: 350px;
  display: block;
  border: 10px solid #fddfcf;
  margin: auto;
  box-shadow: ${theme.boxShadows.images};

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    margin-top: 0;
    margin-bottom: 2rem;
  }
`

const PageTitle = styled.h2`
  text-align: center;
  line-height: 1.4;
`

const HeaderCol = styled.div`
  height: 100%;
  width: 49%;
  margin: 0 .5rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    width: 100%;
    height: auto;
  }
`
const BabyIcon = styled.img`
  max-height: 100%;
  position: absolute;
  bottom: 2%;

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    bottom: 0;
  }
`
const Highlighted = styled.span`
  background-color: #fcdfcf;
  font-weight: bold;
`

const Features = styled.div`
  margin-bottom: 1.7rem;
  font-size: 1.1rem;
  ol {
    line-height: 1.4;
    font-family: "source sans pro", sans-serif;
    li {
      margin-bottom: 0.5rem;
    }
  }
`

export default function Header() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/rps-cover.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const { openModal, closeModal, isOpen, Modal } = useModal({
    background: "rgba(0, 0, 0, 0.8)",
    onOpen: event => {
      // can access: event.portal, event.targetEl, event.event, event.target, etc.
      document.body.style.overflow = "hidden"
    },
    // `onClose` will not have an `event` unless you pass an `event` to `closePortal`
    onClose({ portal, targetEl, event }) {
      document.body.style.overflow = "auto"
    },
  })

  return (
    <Section backgroundColor="secondary">
      <PageTitle>
        Project-based Javascript Course for Beginners
      </PageTitle>
      <HeaderSection>
        <HeaderCol>
          <Img
            css={HeaderImg}
            fluid={data.file.childImageSharp.fluid}
            alt="Tenzin"
          />
        </HeaderCol>
        <HeaderCol>
          {/* <SectionTagLine></SectionTagLine> */}
          <SectionHero>
            Learn to build 3 projects with html, css and vanilla JS <Highlighted>from scratch.</Highlighted> 
          </SectionHero>
          <Features>
            <ol>
              <li>
                You'll learn how to build a digital clock, a temperature converter, and a rock-paper-scissors game.
              </li>
              <li>
                You'll build all these without using any kind of frameworks or libraries. Pure HTML and CSS. Pure Javascript. No crutches.
              </li>
              <li>
                Every line of code, every functionality, every lesson is taught step-by-step keeping absolute beginners in mind.
              </li>
              <li>
                In this free sample course, you'll get a chance to see if my teaching style resonates with you. Then, you can decide if my premium JS course JabJabJavascript is right for you afterwards.
              </li>
            </ol>
          </Features>
          <button
            css={BtnLarge}
            onClick={openModal}
            style={{ fontWeight: "bold" }}
          >
            lemme get it
          </button>
          {isOpen && (
            <Modal>
              <FreeJSModalForm closeModal={closeModal} />
            </Modal>
          )}
        </HeaderCol>
      </HeaderSection>
    </Section>
  )
}
